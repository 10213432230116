<template>
  <v-container class="fill-height secondary un-bg" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card flat class="elevation-3">
          <v-toolbar color="primary" dark flat>
            <v-img max-height="40" max-width="40" src="/logo.png" />
            <v-toolbar-title
              class="text-uppercase font-weight-bold subtitle-2"
              style="padding: 0 3px;"
            >
              Tracking System
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text v-if="!code">
            <p>
              A link has been sent to your email ({{ email }}) to update your passwod.
            </p>
          </v-card-text>
          <v-card-actions v-if="complete">
            <v-spacer />
            <v-btn color="primary" tile depressed @click="navigateToLogin()">
              Back to Login
            </v-btn>
          </v-card-actions>
        </v-card>

        <div>
          <img />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PasswordReset",
  data() {
    return {
      redirect: "/",
      email: null,
    };
  },
  mounted() {
    const { email } = this.$route.params;
    this.email = email;
  },
  methods: {
    ...mapActions("auth", ["login"]),

    navigateToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
